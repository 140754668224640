import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/4ad8a61a-178c-4e37-af95-c5b6c0727dd1/components/global/Analytics.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/4ad8a61a-178c-4e37-af95-c5b6c0727dd1/components/global/ProgressBar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/4ad8a61a-178c-4e37-af95-c5b6c0727dd1/components/global/RealUserMonitoring.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/4ad8a61a-178c-4e37-af95-c5b6c0727dd1/components/global/WebVitals.tsx");
;
import(/* webpackMode: "eager" */ "/build/4ad8a61a-178c-4e37-af95-c5b6c0727dd1/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../assets/fonts/Marianne/static/Marianne-Regular.woff2\",\"weight\":\"400\"},{\"path\":\"../assets/fonts/Marianne/static/Marianne-Medium.woff2\",\"weight\":\"500\"},{\"path\":\"../assets/fonts/Marianne/static/Marianne-Bold.woff2\",\"weight\":\"700\"}],\"fallback\":[\"arial\"]}],\"variableName\":\"marianne\"}");
;
import(/* webpackMode: "eager" */ "/build/4ad8a61a-178c-4e37-af95-c5b6c0727dd1/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/build/4ad8a61a-178c-4e37-af95-c5b6c0727dd1/styles/typography.css");
